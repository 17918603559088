import React, { Fragment, useState } from 'react';
import {
  mdiAlertCircleOutline,
  mdiCardsHeartOutline,
  mdiCheckBold,
} from '@mdi/js';
import { Input, Button, Disclaimer, Text, Checkbox } from 'design-system';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Form, Responsive } from 'UI';
import {
  useAuth,
  useMail,
  useAppState,
  referralCodeSelector,
  familyCodeSelector,
  voucherCodeSelector,
  passCultureCodeSelector,
  newsletterSubscriptionSelector,
} from 'hooks';
import { trackHandler } from 'utils';

import './Signup.scss';
import classNames from 'classnames';

function mapStoreToState(state) {
  return {
    referral: referralCodeSelector(state),
    voucher: voucherCodeSelector(state),
    passCulture: passCultureCodeSelector(state),
    familyCode: familyCodeSelector(state),
    newsletterSubscription: newsletterSubscriptionSelector(state),
  };
}

const errorConfig = [
  {
    field: 'email',
    error: 'E11000 duplicate key error collection',
    userLabel: 'Cette adresse email est déjà utilisée',
  },
  {
    field: 'email',
    error: 'email: invalid',
    userLabel: 'Email invalide',
  },
  {
    field: 'phoneNumber',
    error: 'phone number is invalid',
    userLabel: 'Numéro de téléphone invalide',
  },
  {
    field: 'password',
    error: 'password: should be at least 8 characters',
    userLabel: 'Le mot de passe doit contenir au moins 8 caractères',
  },
];

export default function Signup() {
  const emailPlaceholder = useMail();
  const { signup } = useAuth();
  const history = useHistory();

  const [phoneNum, setPhoneNum] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState(false);

  const [cgu, setCgu] = useState(false);
  const [emailValidation, setEmailValidation] = useState('');
  const [errors, setErrors] = useState([]);

  const [
    { referral, voucher, passCulture, familyCode, newsletterSubscription },
    actions,
  ] = useAppState(mapStoreToState);
  async function handleSubmit(e) {
    e.preventDefault();
    if (!cgu) return;
    if (email !== emailValidation) {
      setErrors([
        { field: 'email', userLabel: 'Les emails ne correspondent pas' },
      ]);
      return;
    }
    try {
      await signup({
        email,
        password,
        phoneNumber: phoneNum.length > 6 ? `+${phoneNum}` : undefined,
      });
      history.replace(
        voucher || passCulture || familyCode ? '/' : '/abonnement'
      );
    } catch (e) {
      const errors = errorConfig.filter(config =>
        e.message.includes(config.error)
      );
      setErrors(errors);
    }
  }

  function ErrorDisclaimers({ field }) {
    return errors
      .filter(error => error.field === field)
      .map((error, index) => (
        <Fragment key={index}>
          <Disclaimer
            type="danger"
            size="large"
            icon={mdiAlertCircleOutline}
            text={error.userLabel}
          />
        </Fragment>
      ));
  }

  return (
    <div id="mio-signup">
      <form onSubmit={handleSubmit}>
        <Form>
          <Responsive>
            <Text bold className="center">
              Inscription
            </Text>
          </Responsive>
          {referral && (
            <Disclaimer
              type="info"
              icon={mdiCardsHeartOutline}
              title="Invitation validée, félicitations !"
              text=" Tu bénéficieras de 2 semaines gratuites supplémentaires suite à ton premier paiement."
            />
          )}
          {voucher && (
            <Disclaimer
              type="success"
              icon={mdiCheckBold}
              title="Carte cadeau validée !"
              text="Ta carte cadeau sera activée à la fin de l'inscription."
            />
          )}
          {passCulture && (
            <Disclaimer
              type="success"
              icon={mdiCheckBold}
              title="Offre pass culture validée !"
              text="Ton offre pass culture sera activée à la fin de l'inscription."
            />
          )}
          {familyCode && (
            <Disclaimer
              type="success"
              icon={mdiCheckBold}
              title="Code famille validé !"
              text="Tu rejoindras l'abonnement famille à la fin de l'inscription."
            />
          )}

          <ErrorDisclaimers field="email" />
          <Input
            onChange={e => setEmail(e.target.value)}
            label="E-mail"
            type="email"
            placeholder={emailPlaceholder}
            required
          />
          <Input
            onChange={e => setEmailValidation(e.target.value)}
            label="Confirmer l'e-mail"
            placeholder={emailPlaceholder}
            required
          />
          <ErrorDisclaimers field="phoneNumber" />
          <div
            className={classNames('phoneContainer', {
              active: active,
            })}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
          >
            <Text className="titlePhoneNum" size="medium">
              Numéro de téléphone mobile
            </Text>
            <PhoneInput
              className={classNames('phoneInput', {
                active: active,
              })}
              buttonStyle={{ backgroundColor: 'transparent' }}
              searchNotFound="Aucun résultat"
              searchPlaceholder="Rechercher"
              disableSearchIcon
              dropdownStyle={{
                backgroundColor: '#151018',
                color: 'white',
                border: '2px solid rgba(255,255,255,0.7)',
                borderRadius: '5px',
              }}
              inputStyle={{
                borderWidth: '2px',
                height: '58px',
                fontSize: '1rem',
              }}
              searchStyle={{ marginInline: '10px' }}
              country={'fr'}
              enableSearch={true}
              value={phoneNum}
              onChange={phone => setPhoneNum(phone)}
            />
          </div>
          <Input
            onChange={e => setPassword(e.target.value)}
            label="Mot de passe"
            type="password"
            placeholder="••••••••••••••••"
            required
          />

          <Checkbox
            id="cgu"
            checked={cgu}
            onChange={({ checked }) => setCgu(checked)}
          >
            <Text size="small">
              Je certifie accepter sans réserve les{' '}
              <a
                href="https://www.notion.so/mangas/Conditions-G-n-rales-d-Utilisation-de-Mangas-io-bf445dbbcf514f218cbf31ac748c04e8"
                onClick={e => trackHandler(e, 'Terms of Service Clicked')}
                target="_blank"
                rel="noreferrer noopener"
              >
                Conditions Générales d'Utilisation (CGU)
              </a>{' '}
              de Mangas.io.
            </Text>
          </Checkbox>
          <Checkbox
            id="newsletter"
            checked={!!newsletterSubscription}
            onChange={({ checked }) =>
              actions.setNewsletterSubscriptionAction(checked)
            }
          >
            <Text size="small">
              J’accepte de recevoir des nouvelles de Mangas.io 1 à 2 fois par
              mois (nouveautés, suggestions, réductions).
            </Text>
          </Checkbox>
          <Button
            type="submit"
            disabled={!cgu}
            variant="primary"
            label="Créer mon compte"
            fullWidth
          />
        </Form>
      </form>
    </div>
  );
}
